<template>
  <v-container fluid ref="content">
    <v-list-item three-line class="mb-0">
      <v-list-item-content>
        <p class="display-1 text--primary">
          {{ $t("reports.titles.chart-report") }}
        </p>
      </v-list-item-content>
    </v-list-item>
    <v-row class="pa-5 pt-0">
      <v-col cols="12" class="pt-0">
        <div class="small">
          <div id="chart-canvas" class="pa-2">
            <v-card-title class="pr-12 justify-center">{{
              $t("reports.titles.titleChartPerformance")
            }}</v-card-title>
            <p
              class="px-0 font-weight-bold"
              style="transform: rotate(270deg); position: absolute; padding-bottom: 120px;"
            >
              {{ $t("reports.titles.volumeBenefit") }}
            </p>
            <div
              style="height: 400px; position: relative"
              v-if="loadingSellers"
            >
              <v-fade-transition>
                <v-overlay absolute color="white">
                  <v-progress-circular
                    indeterminate
                    size="64"
                    color="secondary"
                  />
                </v-overlay>
              </v-fade-transition>
            </div>
            <span v-if="!loadingSellers">
              <chart-graphic
                style="margin-left: 45px"
                type="bubble"
                :options="optionsChart"
              >
              </chart-graphic>
            </span>
            <p class="text-right font-weight-bold">
              {{ $t("reports.titles.bankruptcyChart") }}
            </p>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row class="pa-5">
      <v-col cols="12">
        <v-data-table
          :no-results-text="$t('home.titles.searchNotFound')"
          :items="getSellersByCompany"
          @click:row="filterSeller($event)"
          :headers="headers"
          item-key="id"
          :options.sync="options"
          :loading="loadingSellers"
          class="agTable ml-0 mr-0"
          :items-per-page="10"
          :footer-props="{
            ...rowsPerPageItemsOptions,
            itemsPerPageText: $t('general.table.itemsPerPageText'),
            pageText: '{0}-{1} ' + $t('general.table.pageText') + ' {2}',
          }"
          :no-data-text="$t('general.ui.not_data_found')"
          :loading-text="$t('general.notification.loadData')"
        >
          <template v-slot:top>
            <v-row class="d-flex justify-space-between align-center">
              <v-col lg="4" md="4" sm="12" xl="4">
                <v-text-field onkeypress="return (event.charCode != 34)"  v-on:keyup.86="replace" v-on:keyup.ctrl.86="replace"
                  dense
                  filled
                  rounded
                  color="secondary"
                  v-model="search"
                  append-icon="mdi-magnify"
                  :label="$t('general.titles.filter')"
                  single-line
                  hide-details
                >
                </v-text-field>
              </v-col>
              <v-col lg="4" md="4" sm="8" xl="4">
                <v-toolbar flat color="white">
                  <v-spacer></v-spacer>
                  <v-badge
                    bordered
                    color="secondary"
                    overlap
                    :content="cantFilters"
                    left
                    :value="cantFilters"
                  >
                    <v-btn
                      small
                      tile
                      :elevation="0"
                      color="#d8d8d896"
                      style="border-radius: 1.5em;"
                      @click="openFilters"
                      class="mb-2 mr-1"
                    >
                      <v-icon small color="#6D6D6D" left
                        >mdi-filter-variant</v-icon
                      >
                      <span style="color:#6D6D6D">{{
                        $t("general.buttons.filter")
                      }}</span>
                    </v-btn>
                  </v-badge>
                </v-toolbar>
              </v-col>
            </v-row>
            <!--FILTERS TOOLBAR *********************************************************************-->
            <v-toolbar
              class="mb-3"
              flat
              style="background-color:#f4f2f2; border-radius: 0.3em"
              v-if="filtersChips && filtersChips.length > 0"
            >
              <v-row style="display: contents">
                <v-col cols="12">
                  <v-chip-group active-class="neuter--text">
                    <v-chip
                      small
                      color="#FFFFFF"
                      close
                      v-for="chip in filtersChips"
                      :key="chip.value"
                      @click:close="cleanFilter(chip.value)"
                    >
                      <v-avatar left>
                        <v-icon small>mdi-filter-variant</v-icon>
                      </v-avatar>
                      {{ chip.name }}
                    </v-chip>
                  </v-chip-group>
                </v-col>
              </v-row>
            </v-toolbar>
          </template>
          <template v-slot:item.status="{ item }">
            <v-chip x-small :color="getColor(item.data[0].x)" dark> </v-chip>
          </template>
          <template v-slot:item.data[0].x="{ item }">
            <v-btn text :color="getColor(item.data[0].x)">{{
              item.data[0].x + "%"
            }}</v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import _ from "lodash";
import ChartGraphic from "./components/ChartGraphic";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { mapActions, mapGetters } from "vuex";
import i18n from "@/plugins/i18n";

const FILTER_VALUES = [
  "template_names",
  "company_id",
  "process_ids",
  "products_ids",
  "seasons_ids",
  "person_in_charge_ids",
  "start_record_date_time",
  "end_record_date_time",
  "structure",
  "pageableDTO",
  "record_ids",
  "storage_devices_ids",
  "min_punctuation",
  "max_punctuation",
  "surveyStateType",
  "storage_device_types_ids",
  "sdi_origin_ids",
  "expiration_date",
  "document_date",
  "document_authority_ids",
  "document_type_ids",
  "document_description_ids",
  "sdi_destination_ids",
  "lots",
  "contracts_date",
  "volumeB_range",
  "volumeN_range",
  "quebra_range",
  "withExternalVisibility"
];

export default {
  name: "SellersReport",
  components: { ChartGraphic },
  data() {
    return {
      profile: null,
      search: null,
      rowsPerPageItemsOptions: {
        itemsPerPageOptions: [10, 20, 50, 100, 200],
      },
      show: false,
      allVolume: [],
      allQuebra: [],
      config: {},
      optionsChart: {},
      options: {},
      backup: null,
    };
  },

  watch: {
    options: {
      handler() {
        this.optionsTable = this.options;
        this.getResultsPaginatedByCompany();
      },
      deep: true,
    },
  },

  mounted() {
    this.$store.commit("ui/FILTERS_ORIGINAL_STATE");
    let filtersSellers = JSON.parse(localStorage.getItem("filtersSellers"));

    if (filtersSellers) {
      this.contractsRanges.volumeN =
        JSON.stringify(filtersSellers.contractsRanges.volumeN) ===
        JSON.stringify([0, 100])
          ? [this.volumesN["volumeN_min"], this.volumesN["volumeN_max"]]
          : filtersSellers.contractsRanges.volumeN;
      this.contractsRanges.volumeB =
        JSON.stringify(filtersSellers.contractsRanges.volumeB) ===
        JSON.stringify([0, 100])
          ? [this.volumesB["volumeB_min"], this.volumesB["volumeB_max"]]
          : filtersSellers.contractsRanges.volumeB;
      this.contractsRanges.quebra =
        JSON.stringify(filtersSellers.contractsRanges.quebra) ===
        JSON.stringify([0, 100])
          ? [this.quebra["quebra_min"], this.quebra["quebra_max"]]
          : filtersSellers.contractsRanges.quebra;
      this.date.filtersContractsDate = filtersSellers.filtersSellersDate
        ? filtersSellers.filtersSellersDate
        : [];
      this.date["contracts_dates"] = filtersSellers.sellers_dates
        ? filtersSellers.sellers_dates
        : "";
      this.filtersObjects = filtersSellers.filtersObjects
        ? filtersSellers.filtersObjects
        : [];
    } else {
      this.contractsRanges.volumeN = [
        this.volumesN["volumeN_min"],
        this.volumesN["volumeN_max"],
      ];
      this.contractsRanges.volumeB = [
        this.volumesB["volumeB_min"],
        this.volumesB["volumeB_max"],
      ];
      this.contractsRanges.quebra = [
        this.quebra["quebra_min"],
        this.quebra["quebra_max"],
      ];
    }

    if (
      this.date.filtersContractsDate &&
      this.date.filtersContractsDate.length > 0
    )
      this.processRangeDates("contracts_dates", "filtersContractsDate");

    if (this.filtersObjects) {
      this.closeFilterDialog();
    }

    this.$root.$on("setFiltersSellers", () => {
      this.closeFilterDialog();
    });

    this.$root.$on("getResultsPaginatedByCompany", () => {
      this.getResultsPaginatedByCompany();
    });
  },

  created() {
    this.profile = JSON.parse(localStorage.getItem("profile"));
    this.initialize();
  },

  methods: {
    ...mapActions({
      getSellers: "reports/getSellers",
    }),
    saveAsPDF() {
      html2canvas(document.getElementById("chart-canvas")).then((canvas) => {
        let img = canvas.toDataURL(); //image data of canvas
        let doc = new jsPDF("landscape");
        doc.addImage(img, "JPEG", 10, 10, 270, 150);
        doc.save("Graphic Sellers.pdf");
      });
    },
    getColor(x) {
      if (parseFloat(x) === 0) {
        return "gray";
      } else {
        if (parseFloat(x) < 0) {
          return "success";
        } else {
          return "error";
        }
      }
    },
    initialize() {
      this.generateOptions();
    },
    generateDatasets() {
      this.datasets = this.sellers;
      _.forEach(this.datasets, (dataset) => {
        _.forEach(dataset.data, (object) => {
          object["y"] = object["volumeBenefit"];
          object["x"] = object["quebra"];
          delete object["volumeBenefit"];
          delete object["quebra"];
        });

        if (dataset.data[0].x < 0) {
          Object.assign(dataset, {
            backgroundColor: ["rgba(94,175,76,0.50)"],
            borderColor: "#4CAF50",
            borderWidth: 3,
          });
        } else if (dataset.data[0].x > 0) {
          Object.assign(dataset, {
            backgroundColor: ["rgba(232,59,54,0.50)"],
            borderColor: "#E83B36",
            borderWidth: 3,
          });
        } else {
          Object.assign(dataset, {
            backgroundColor: ["rgba(108,117,125,0.50)"],
            borderColor: "#6C757D",
            borderWidth: 3,
          });
        }
        Object.assign(dataset.data[0], { ...dataset.data[0], r: 7 });
      });

      _.forEach(this.datasets, (dataset) => {
        this.allVolume.push(dataset.data[0].y);
        this.allQuebra.push(dataset.data[0].x);
      });

      this.allVolume.sort((a, b) => {
        return a - b;
      });

      this.allQuebra.sort(function(a, b) {
        return a - b;
      });

      if (this.allVolume.length > 0) {
        this.rangesChart.volume[0] = this.allVolume[0];
        this.rangesChart.volume[1] = this.allVolume[this.allVolume.length - 1];
      } else {
        this.rangesChart.volume = [0, 100];
      }
      if (this.allQuebra.length > 0) {
        this.rangesChart.quebra[0] = this.allQuebra[0];
        this.rangesChart.quebra[1] = this.allQuebra[this.allQuebra.length - 1];
      } else {
        this.rangesChart.quebra = [0, 100];
      }

      this.filtersChart.volumes = this.allVolume;
      this.filtersChart.quebras = this.allQuebra;
    },
    generateOptions() {
      this.optionsChart = {
        tooltips: {
          titleFontSize: 10,
          bodyFontSize: 20,
          callbacks: {
            label: function(tooltipItem, data) {
              let seller = data.datasets[tooltipItem.datasetIndex].seller || "";
              let x = data.datasets[tooltipItem.datasetIndex].data[0].x || 0;
              let y = data.datasets[tooltipItem.datasetIndex].data[0].y || 0;
              let r = data.datasets[tooltipItem.datasetIndex].data[0].r || "";

              return (
                seller +
                ": " +
                i18n.t("reports.titles.volume") +
                ": " +
                y +
                " (" +
                i18n.t("reports.titles.bags") +
                ")" +
                ", " +
                "≠" +
                i18n.t("reports.titles.bankruptcy") +
                ": " +
                x +
                " (%)"
              );
            },
          },
        },
        lines: [
          {
            type: "vertical",
            x: 0.001,
            color: "rgba(232,59,54,0.50)",
          },
        ],
        legend: {
          display: false,
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              ticks: {
                callback: function(value, index, values) {
                  return value + "%";
                },
                suggestedMin: this.rangesChart.quebra
                  ? this.rangesChart.quebra[0] - 100
                  : this.filtersChart.quebras[this.filtersChart.quebras[0]],
                suggestedMax: this.rangesChart.quebra
                  ? this.rangesChart.quebra[1] + 100
                  : this.filtersChart.quebras[
                      this.filtersChart.quebras.length - 1
                    ],
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                suggestedMin: 0,
                suggestedMax: this.rangesChart.volume
                  ? this.rangesChart.volume[1] + 1000
                  : this.filtersChart.volumes[
                      this.filtersChart.volumes.length - 1
                    ],
              },
            },
          ],
        },
      };
    },
    openFilters() {
      this.type = "sellers";
      this.drawer = true;
    },
    closeFilterDialog() {
      this.applyFilters();
      this.filteredChips();
      this.getCantFilters();
      this.show = false;
    },
    filterSeller(event) {
      let filtersContracts = JSON.parse(
        localStorage.getItem("filtersContracts")
      );
      let item = _.find(this.sellersNames, { name: event.seller });
      filtersContracts.filtersObjects.sellers_ids = [item.id];
      localStorage.setItem(
        "filtersContracts",
        JSON.stringify(filtersContracts)
      );
      this.$router.push({ name: "contracts-report" });
    },
    getResultsPaginatedByCompany() {
      const direction =
        this.options.sortDesc.length === 0 || this.options.sortDesc[0]
          ? "desc"
          : "asc";
      const sort =
        this.options.sortBy.length === 0
          ? "id"
          : _.filter(this.headers, { value: this.options.sortBy[0] })[0].val;
      this.filters.pageableDTO = {
        page: this.options.page - 1,
        size: this.options.itemsPerPage,
        sortBy: sort,
        direction: direction,
      };

      this.filters.company_id = this.profile.company_id;

      const keys = [
        "company_id",
        "pageableDTO",
        "sellers_dates",
        "contracts_names",
        "quebra_range",
        "sellers_ids",
        "volumeB_range",
        "volumeN_range",
      ];
      const filters = _.cloneDeep(_.pick(this.filters, keys));

      this.getSellers([this.$toast, filters])
        .then(() => {
          this.generateDatasets();
        })
        .finally(() => {
          this.datasets = this.sellers;
          this.datasets = _.filter(this.datasets, (o) => {
            return (
              o.data[0].y >= this.rangesChart.volume[0] &&
              o.data[0].y <= this.rangesChart.volume[1] &&
              o.data[0].x >= this.rangesChart.quebra[0] &&
              o.data[0].x <= this.rangesChart.quebra[1]
            );
          });
          this.chart.config.data.datasets = this.datasets;
          this.chart.update();
        });
    },
    applyFilters() {
      this.datasets = [];
      let filtersSellers = JSON.parse(localStorage.getItem("filtersSellers"));
      if (filtersSellers) {
        this.filters.sellers_ids =
          filtersSellers.filtersObjects.sellers_ids &&
          filtersSellers.filtersObjects.sellers_ids.length > 0
            ? filtersSellers.filtersObjects.sellers_ids
            : null;
        this.filters.contracts_names =
          filtersSellers.filtersObjects.contracts_names &&
          filtersSellers.filtersObjects.contracts_names.length > 0
            ? filtersSellers.filtersObjects.contracts_names
            : null;
        this.filters.sellers_dates =
          filtersSellers.filtersSellersDate &&
          filtersSellers.filtersSellersDate.length > 0
            ? [
                new Date(
                  filtersSellers.filtersSellersDate[0] + "T00:00:00.000Z"
                )
                  .toISOString()
                  .slice(0, 19),
                new Date(
                  filtersSellers.filtersSellersDate[1] + "T23:59:59.000Z"
                )
                  .toISOString()
                  .slice(0, 19),
              ]
            : [];
        this.filters.volumeB_range =
          filtersSellers.contractsRanges.volumeB &&
          filtersSellers.contractsRanges.volumeB.length > 0 &&
          JSON.stringify(filtersSellers.contractsRanges.volumeB) !==
            JSON.stringify([0, 100])
            ? filtersSellers.contractsRanges.volumeB
            : [];
        this.filters.volumeN_range =
          filtersSellers.contractsRanges.volumeN &&
          filtersSellers.contractsRanges.volumeN.length > 0 &&
          JSON.stringify(filtersSellers.contractsRanges.volumeN) !==
            JSON.stringify([0, 100])
            ? filtersSellers.contractsRanges.volumeN
            : [];
        this.filters.quebra_range =
          filtersSellers.contractsRanges.quebra &&
          filtersSellers.contractsRanges.quebra.length > 0 &&
          JSON.stringify(filtersSellers.contractsRanges.quebra) !==
            JSON.stringify([0, 100])
            ? filtersSellers.contractsRanges.quebra
            : [];
      }
      this.getResultsPaginatedByCompany();
    },
    filteredChips() {
      let chips = [];
      _.forEach(this.filters, (value, key) => {
        if (
          ![...FILTER_VALUES].includes(key) &&
          this.filters[key] !== null &&
          this.filters[key].length > 0
        ) {
          chips.push({
            value: key,
            name: _.filter(this.filterHeader, { val: key })[0].text,
          });
        }

        if (
          key === "volumeN_range" &&
          (parseInt(this.contractsRanges.volumeN[0]) >
            this.volumesN["volumeN_min"] ||
            parseInt(this.contractsRanges.volumeN[1]) <
              this.volumesN["volumeN_max"])
        ) {
          chips.push({
            value: key,
            name: _.filter(this.filterHeader, { val: key })[0].text,
          });
        }
        if (
          key === "volumeB_range" &&
          (parseInt(this.contractsRanges.volumeB[0]) >
            this.volumesB["volumeB_min"] ||
            parseInt(this.contractsRanges.volumeB[1]) <
              this.volumesB["volumeB_max"])
        ) {
          chips.push({
            value: key,
            name: _.filter(this.filterHeader, { val: key })[0].text,
          });
        }
        if (
          key === "quebra_range" &&
          (parseInt(this.contractsRanges.quebra[0]) >
            this.quebra["quebra_min"] ||
            parseInt(this.contractsRanges.quebra[1]) <
              this.quebra["quebra_max"])
        ) {
          chips.push({
            value: key,
            name: _.filter(this.filterHeader, { val: key })[0].text,
          });
        }
      });

      this.filtersChips = chips;
    },
    getCantFilters() {
      let cant = 0;
      _.forEach(this.filters, (value, key) => {
        if (
          ![...FILTER_VALUES, "sellers_dates"].includes(key) &&
          value !== null
        ) {
          cant += value.length;
        }
        cant += key === "sellers_dates" && value && value.length > 0 ? 1 : 0;
        cant +=
          key === "volumeN_range" &&
          (parseInt(value[0]) > this.volumesN["volumeN_min"] ||
            parseInt(value[1]) < this.volumesN["volumeN_max"])
            ? 1
            : 0;
        cant +=
          key === "volumeB_range" &&
          (parseInt(value[0]) > this.volumesB["volumeB_min"] ||
            parseInt(value[1]) < this.volumesB["volumeB_max"])
            ? 1
            : 0;
        cant +=
          key === "quebra_range" &&
          (parseInt(value[0]) > this.quebra["quebra_min"] ||
            parseInt(value[1]) < this.quebra["quebra_max"])
            ? 1
            : 0;
      });
      this.cantFilters = cant;
    },
    cleanFilter(tag) {
      let filtersSellers = JSON.parse(localStorage.getItem("filtersSellers"));
      if (this.filters[tag]) {
        if (this.filtersObjects[tag]) {
          this.filtersObjects[tag] = [];
          filtersSellers.filtersObjects[tag] = [];
        }
        if (tag === "volumeB_range") {
          this.contractsRanges.volumeB = [
            this.volumesB["volumeB_min"],
            this.volumesB["volumeB_max"],
          ];
          filtersSellers.contractsRanges["volumeB"] = [
            this.volumesB["volumeB_min"],
            this.volumesB["volumeB_max"],
          ];
          this.filters["volumeB_range"] = [];
        } else if (tag === "volumeN_range") {
          this.contractsRanges.volumeN = [
            this.volumesN["volumeN_min"],
            this.volumesN["volumeN_max"],
          ];
          filtersSellers.contractsRanges["volumeN"] = [
            this.volumesN["volumeN_min"],
            this.volumesN["volumeN_max"],
          ];
          this.filters["volumeN_range"] = [];
        } else if (tag === "quebra_range") {
          this.contractsRanges.quebra = [
            this.quebra["quebra_min"],
            this.quebra["quebra_max"],
          ];
          filtersSellers.contractsRanges["quebra"] = [
            this.quebra["quebra_min"],
            this.quebra["quebra_max"],
          ];
          this.filters["quebra_range"] = [];
        } else if (tag === "sellers_dates") {
          this.filtersObjects.contracts_dates = "";
          this.filters["sellers_dates"] = [];
          this.date.filtersContractsDate = [];
          filtersSellers.sellers_dates = false;
          filtersSellers.filtersSellersDate = [];
          filtersSellers.filtersObjects.sellers_dates = "";
        } else {
          this.filters[tag] = null;
          filtersSellers[tag] = null;
        }
      }
      localStorage.setItem("filtersSellers", JSON.stringify(filtersSellers));
      this.applyFilters();
      this.filteredChips();
      this.getCantFilters();
    },
    processRangeDates(stringTag, field) {
      let [year, month, day] = "-";
      let [year2, month2, day2] = "-";
      if (this.date[field] && this.date[field].length > 1) {
        if (this.date[field][0]) {
          [year, month, day] = this.date[field][0].split("-");
        }
        if (this.date[field][1]) {
          [year2, month2, day2] = this.date[field][1].split("-");
        }
        this.filtersObjects[
          stringTag
        ] = `${day}/${month}/${year} ~ ${day2}/${month2}/${year2}`;
      } else {
        this.filtersObjects[stringTag] = [];
      }
    },
    getSellersGetters(search) {
      let items = this.datasets;
      if (search) {
        items = this.searchOnAll(items);
      }
      return items;
    },
    searchOnAll(items) {
      return items.filter((item) => {
        return Object.values(item)
          .join(",")
          .toUpperCase()
          .includes(this.search.toUpperCase());
      });
    },
    replace(event){
      event.currentTarget.value =  event.currentTarget.value.toString().replaceAll('"','')
      this.search =  event.currentTarget.value.toString().replaceAll('"','')
    }
  },

  computed: {
    getSellersByCompany() {
      return this.getSellersGetters(this.search);
    },
    ...mapGetters({
      sellers: "reports/sellers",
      sellersNames: "reports/sellersNames",
      loadingSellers: "reports/loadingSellers",
      quebra: "reports/quebra",
      volumesN: "reports/volumesN",
      volumesB: "reports/volumesB",

      //----ui-----------------------------------------
      filterHeader: "ui/GET_HEADERS_CONTRACTS",
    }),
    filters: {
      get() {
        return this.$store.getters["ui/GET_FILTERS"];
      },
      set(val) {
        this.$store.dispatch("ui/CONTRACTS_RANGES", val);
      },
    },
    filtersObjects: {
      get() {
        return this.$store.getters["ui/GET_OBJECTS_TO_FILTERS"];
      },
      set(val) {
        this.$store.dispatch("ui/SET_OBJECTS_TO_FILTERS", val);
      },
    },
    date: {
      get() {
        return this.$store.getters["ui/GET_DATE_FILTERS"];
      },
      set(val) {
        this.$store.dispatch("ui/SET_DATE_FILTERS", val);
      },
    },
    optionsTable: {
      get() {
        return this.$store.getters["ui/GET_OPTIONS_TABLE"];
      },
      set(val) {
        this.$store.dispatch("ui/SET_OPTIONS_TABLE", val);
      },
    },
    filtersChips: {
      get() {
        return this.$store.getters["ui/GET_FILTERS_CHIPS"];
      },
      set(val) {
        this.$store.dispatch("ui/SET_FILTERS_CHIPS", val);
      },
    },
    cantFilters: {
      get() {
        return this.$store.getters["ui/GET_COUNT_FILTERS"];
      },
      set(val) {
        this.$store.dispatch("ui/SET_COUNT_FILTERS", val);
      },
    },
    chart: {
      get() {
        return this.$store.getters["reports/chart"];
      },
      set(val) {
        this.$store.commit("reports/SET_CHART", val);
      },
    },
    filtersChart: {
      get() {
        return this.$store.getters["ui/GET_CHART_DATA"];
      },
      set(val) {
        this.$store.dispatch("ui/CHART_DATA", val);
      },
    },
    rangesChart: {
      get() {
        return this.$store.getters["ui/GET_CHART_RANGES"];
      },
      set(val) {
        this.$store.commit("ui/CHART_RANGES", val);
      },
    },
    drawer: {
      get() {
        return this.$store.getters["ui/GET_DRAWER"];
      },
      set(val) {
        this.$store.dispatch("ui/SET_DRAWER", val);
      },
    },
    datasets: {
      get() {
        return this.$store.getters["reports/datasets"];
      },
      set(val) {
        this.$store.commit("reports/SET_DATASETS", val);
      },
    },
    range: {
      get() {
        return this.$store.getters["reports/range"];
      },
      set(val) {
        this.$store.commit("reports/SET_RANGE", val);
      },
    },
    sheet: {
      get() {
        return this.$store.getters["reports/sheetSeller"];
      },
      set(val) {
        this.$store.commit("reports/SET_SHEET_SELLER", val);
      },
    },
    sellers: {
      get() {
        return this.$store.getters["reports/sellers"];
      },
      set(val) {
        this.$store.commit("reports/SET_DATA_SELLERS", val);
      },
    },
    type: {
      get() {
        return this.$store.getters["ui/GET_FILTER_TYPE"];
      },
      set(val) {
        this.$store.dispatch("ui/SET_FILTER_TYPE", val);
      },
    },
    contractsRanges: {
      get() {
        return this.$store.getters["ui/GET_CONTRACTS_RANGES"];
      },
      set(val) {
        this.$store.commit("ui/CONTRACTS_RANGES", val);
      },
    },
    contractsData: {
      get() {
        return this.$store.getters["ui/GET_CONTRACTS_DATA"];
      },
      set(val) {
        this.$store.commit("ui/CONTRACTS_DATA", val);
      },
    },
    headers: () => [
      {
        text: "",
        value: "status",
        sortable: false,
        width: 150,
      },
      {
        text: i18n.t("reports.titles.sellers"),
        value: "seller",
        sortable: false,
        type: "text",
      },
      {
        text: i18n.t("reports.titles.volumeChart"),
        value: "data[0].volumen",
        sortable: false,
        type: "text",
      },
      {
        text: i18n.t("reports.titles.volumeBenefit"),
        value: "data[0].y",
        sortable: false,
        type: "text",
      },
      {
        text: i18n.t("reports.titles.bankruptcyChart"),
        value: "data[0].x",
        sortable: false,
        type: "text",
      },
    ],
  },
  destroyed() {
    this.rangesChart.volume = [0, 100];
    this.rangesChart.quebra = [0, 100];
    this.$root.$off("setFiltersSellers");
    this.$root.$off("getResultsPaginatedByCompany");
  },
};
</script>

<style scoped>
:deep(.v-chip-group .v-slide-group__content) {
  display: flex !important;
  justify-content: flex-start !important;
}
:deep(.v-toolbar__content) {
  padding: 0 !important;
}
</style>
