<template>
    <div class="d-flex justify-content-center">
        <canvas height="400" id="myChart"/>
    </div>
</template>

<script>
    import Chart from "chart.js";

    export default {
        name: "ChartGraphic",
        props: {
            type: {
                type: String,
                required: true
            },
            options: {
                type: Object,
                required: true
            },
        },
        computed: {
            datasets: {
                get() {
                    return this.$store.getters['reports/datasets'];
                },
                set(val) {
                    this.$store.commit('reports/SET_DATASETS', val);
                }
            },
            chart: {
                get(){
                    return this.$store.getters['reports/chart'];
                },
                set(val){
                    this.$store.dispatch('reports/addChart', val)
                }
            },
            sellers: {
                get() {
                    return this.$store.getters['reports/sellers'];
                },
                set(val) {
                    this.$store.commit('reports/SET_DATA_SELLERS', val);
                }
            },
        },
        created(){
            let chartPluginLineaHorizontal = {
                beforeDraw: function(chartobj) {
                    if (chartobj.options.lines) {
                        let ctx = chartobj.chart.ctx;
                        for (let idx = 0; idx < chartobj.options.lines.length; idx++) {
                            let line = chartobj.options.lines[idx];
                            line.iniCoord = [0, 0];
                            line.endCoord = [0, 0];
                            line.color = line.color ? line.color : "red";
                            line.label = line.label ? line.label : "";
                            if (line.type === "horizontal" && line.y) {
                                line.iniCoord[1] = line.endCoord[1] = chartobj.scales["y-axis-0"].getPixelForValue(line.y);
                                line.endCoord[0] = chartobj.chart.width;
                            } else if (line.type === "vertical" && line.x) {
                                line.iniCoord[0] = line.endCoord[0] = chartobj.scales["x-axis-0"].getPixelForValue(line.x);
                                line.endCoord[1] = chartobj.chart.height;
                            }
                            ctx.beginPath();
                            ctx.moveTo(line.iniCoord[0], line.iniCoord[1]);
                            ctx.lineTo(line.endCoord[0], line.endCoord[1]);
                            ctx.strokeStyle = line.color;
                            ctx.stroke();
                            ctx.fillStyle = line.color;
                            ctx.fillText(line.label, line.iniCoord[0] + 3, line.iniCoord[1] + 3);
                        }
                    }
                }
            };
            Chart.pluginService.register(chartPluginLineaHorizontal);
        },
        mounted() {
            this.createChart();
        },
        methods: {
            async createChart() {
                this.chart = new Chart(
                    'myChart',
                    {
                        type: this.type,
                        data: {
                            datasets: this.datasets
                        },
                        options: this.options,
                    },
                );
                return this.chart;
            }
        }
    }
</script>

<style scoped>
</style>